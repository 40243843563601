export default function validate(values){
    let errors = {};
    
    if(!values.name.trim()){
        errors.name = "Name required";
    }

    if(!values.email.trim()){
        errors.email = "Email required";
    // uh oh
    } else if (!/^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.email)){
        errors.email = "Invalid email address";
    }

    if(!values.subject){
        errors.subject = "Subject required";
    }

    if(!values.text){
        errors.text = "Message required";
    }

    return errors;
}