import React from 'react';
import {
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkItems,
    FooterLinkTitle,
    FooterLink,
    SocialMedia,
    SocialMediaWrap,
    SocialIconLink,
    SocialLogo,
    SocialIcons,
    WebsiteRights
} from './FooterElements';
import { FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';

const Footer = () => {

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    return (    
        <FooterContainer id="contact">
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>
                                Excel Search
                            </FooterLinkTitle>
                            <FooterLink href="#about">About</FooterLink>
                            <FooterLink href="#services">Services</FooterLink>
                            <FooterLink href="#reviews">Reviews</FooterLink>
                            <FooterLink href="#offices">Offices</FooterLink>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle>
                                Beena Rajesh
                            </FooterLinkTitle>
                            <FooterLink href="tel: +91982-116-2516">+91 9821162516</FooterLink>
                            <FooterLink href="https://mail.google.com/mail/?view=cm&fs=1&to=excelsearch@esonline.in" target="_blank">Email</FooterLink>
                            <FooterLink href="https://www.linkedin.com/in/beenarajesh/" target="_blank">LinkedIn</FooterLink>
                            <FooterLink href="https://twitter.com/BeeMe999?s=08" target="_blank">Twitter</FooterLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>
                                Amrita Rode
                            </FooterLinkTitle>
                            <FooterLink href="tel: +91982-003-4449">+91 9820034449</FooterLink>
                            <FooterLink href="https://mail.google.com/mail/?view=cm&fs=1&to=excelsearch@esonline.in" target="_blank">Email</FooterLink>
                            <FooterLink href="https://www.linkedin.com/in/aamrita-rode-690577134" target="_blank">LinkedIn</FooterLink>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle>
                                Developer
                            </FooterLinkTitle>
                            <FooterLink href="https://mail.google.com/mail/?view=cm&fs=1&to=rajesh.dhruvk@gmail.com" target="_blank">Email</FooterLink>
                            <FooterLink href="https://www.linkedin.com/in/dhruv-krishnamachari-3a3a071a2/" target="_blank">LinkedIn</FooterLink>
                            <FooterLink href="https://github.com/charsterekt" target="_blank">GitHub</FooterLink>
                            <FooterLink href="https://discordapp.com/users/417387714367979520" target="_blank">Discord</FooterLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to='/' onClick={toggleHome}>Excel Search</SocialLogo>
                        <WebsiteRights>Excel Search (C) 2007-{new Date().getFullYear()}</WebsiteRights>
                        <SocialIcons>
                            <SocialIconLink href="//Twitter.com/Excelsearch" target="_blank" aria-label="Twitter">
                                <FaTwitter />
                            </SocialIconLink>
                            <SocialIconLink href="//Linkedin.com/in/excel-search-6b7249215" target="_blank" aria-label="LinkedIn">
                                <FaLinkedin />
                            </SocialIconLink>
                            <SocialIconLink href="//Instagram.com/excelsearch" target="_blank" aria-label="Instagram">
                                <FaInstagram />
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer;
