import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const Container = styled.div`
    min-height: 800px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 0;
    overflow: hidden;
    /* background: linear-gradient(
        108deg,
        rgba(69, 133, 237, 1) 0%,
        rgba(49, 111, 212, 1) 100%
    ); */
    background: #232127;
    
    @media screen and (max-width: 600px){
        position: static;
        min-height: 900px;
    }

    @media screen and (max-width: 480px){
        position: static;
        min-height: 930px;
    }

    @media screen and (max-width: 400px){
        position: static;
        min-height: 848px;
    }

    @media screen and (max-width: 374px){
        position: static;
        min-height: 830px;
    }

    @media screen and (max-height: 860px){
        position: static;
        min-height: 860px;
    }
`;

export const FormWrap = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    /* @media screen and (max-width: 400px){
        height: 80%;
    } */
`;

export const Icon = styled(Link)`
    margin-left: 32px;
    margin-top: 32px;
    margin-bottom: 16px;
    text-decoration: none;
    color: white;
    font-weight: 700;
    font-size: 32px;
    
    @media screen and (max-width: 480px){
        margin-left: 16px;
        margin-top: 8px;
    }
`;

export const FormContent = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    /* @media screen and (max-width: 480px){
        padding: 0px;
    } */
`;

export const Form = styled.form`
    background: #101522;
    max-width: 800px;
    height: 750px;
    width: 100%;
    z-index: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 auto;
    padding: 80px 16px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);

    @media screen and (max-width: 735px){
        padding: 32px 16px;
        grid-template-columns: 1fr;
    }

    @media screen and (max-width: 768px){
        width: 80%;
    }
`;

export const FormDiv = styled.div`
    display: grid;
    justify-content: center;
    align-items: center;
`;

export const ImgDiv = styled.div`
    display: grid;

    @media screen and (max-width: 735px){
        display: none;
    }
`;

export const FormImg = styled.img`
    margin: 20px;
    height: 600px;
    width: 300px;

    @media screen and (max-width: 768px){
        height: 600px;
        width: 280px;
        margin-left: 0;
    }
`;

export const FormH1 = styled.h1`
    margin-bottom: 40px;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
`;

export const FormLabel = styled.label`
    margin-bottom: 8px;
    font-size: 14px;
    color: #fff;
`;

export const FormInput = styled.input`
    padding: 10px 10px;
    margin-bottom: 16px;
    border: none;
    border-radius: 4px;
`;

export const FormText = styled.textarea`
    padding: 16px 16px;
    margin-bottom: 16px;
    border: none;
    border-radius: 4px;
    resize: none;

    ::-webkit-scrollbar
    {
        width: 8px;
        background-color: #F5F5F5;
        float: left;
        height: 60px;
        background: #F5F5F5;
        overflow-y: scroll;
    }
    ::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 4px;
        background-color: #F5F5F5;
    }
    ::-webkit-scrollbar-thumb
    {
        border-radius: 4px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: lightgrey;
    }
`;

export const FormButton = styled.button`
    background: #4585ed;
    padding: 16px 0;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    margin-top: 32px;

    &:hover {
        color: #4585ed;
        background-color: #fff;
        transition: 0.2s ease-in-out;
    }
`;

export const Text = styled.span`
    text-align: center;
    /* margin-bottom: 24px; */
    color: #fff;
    font-size: 14px;
`;

export const Err = styled.p`
    color: red;
    font-size: 12px;
    margin-bottom: 16px;
`;

export const FormSpan = styled.span`
    color: red;
`;

export const Ocean = styled.div`
    height: 15%;
    width:100%;
    position:absolute;
    bottom:0;
    left:0;
    background: #015871;
    z-index: -100;

    @media screen and (max-width: 767px){
        display: none;
    }
`;

export const Wave = styled.div`
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x; 
    position: absolute;
    top: -198px;
    width: 6400px;
    height: 198px;
    animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);


    &:nth-of-type(2) {
    top: -175px;
    animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
    opacity: 1;
    }

    @keyframes wave {
        0% {
            margin-left: 0;
        }
        100% {
            margin-left: -1600px;
        }
    }

    @keyframes swell {
        0%, 100% {
            transform: translate3d(0,-25px,0);
        }
        50% {
            transform: translate3d(0,5px,0);
        }
    }
`;