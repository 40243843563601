import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const Container = styled.div`
    min-height: 692px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 0;
    overflow: hidden;
    background: #101522;
`;

export const Wrapper = styled.div`
    background: white;
    max-width: 400px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 400px;
    height: 400px;
    top:0;
	bottom: 0;
	left: 0;
	right: 0;
  	justify-content: center;
    align-items: center;
	margin: auto;
    border-radius: 16px;

    @media screen and (max-width: 480px){
        height: 400px;
        width: 250px;
    }
`;

export const SuccessImg = styled.img`
    max-width: 250px;
    max-height: 250px;

    @media screen and (max-width: 480px){
        max-width: 200px;
        height: 200px;
        margin-top: 16px;
        margin-bottom: 16px;
    }
`;

export const HomeButton = styled(Link)`
    color: white;
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    background: black;
    border-radius: 50px;
    height: 64x;
    width: 128px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 16px;

    &:hover{
        background-color: #4585ed;

        transition: all 0.2s ease-in-out;
    }
`;

export const H1 = styled.h1`
    font-size: 2rem;
    color: black;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    @media screen and (max-width: 480px){
        font-size: 1.5rem;
    }    
`;

export const Ocean = styled.div`
    height: 15%;
    width:100%;
    position:absolute;
    bottom:0;
    left:0;
    background: #015871;
    z-index: -100;

    @media screen and (max-width: 767px){
        display: none;
    }
`;

export const Wave = styled.div`
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x; 
    position: absolute;
    top: -198px;
    width: 6400px;
    height: 198px;
    animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);


    &:nth-of-type(2) {
    top: -175px;
    animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
    opacity: 1;
    }

    @keyframes wave {
        0% {
            margin-left: 0;
        }
        100% {
            margin-left: -1600px;
        }
    }

    @keyframes swell {
        0%, 100% {
            transform: translate3d(0,-25px,0);
        }
        50% {
            transform: translate3d(0,5px,0);
        }
    }
`;