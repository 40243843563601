import React, {useState} from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import { 
    homeObjOne,
    homeObjTwo,
    homeObjThree
} from '../components/InfoSection/Data';
import Reviews from '../components/Reviews';
import Footer from '../components/Footer';

const Home = () => {

    // State for sidebar menu
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
        setIsOpen(!isOpen);
    }

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle}/>
            <HeroSection />
            <InfoSection {...homeObjOne}/>
            <InfoSection {...homeObjTwo}/>
            <Reviews />
            <InfoSection {...homeObjThree}/>
            <Footer />
        </>
    )
}

export default Home;
