import React from 'react';
import {
    Container,
    FormWrap,
    Icon,
    FormContent,
    Form,
    FormH1,
    FormLabel,
    FormInput,
    FormText,
    Text,
    FormButton,
    Err,
    FormSpan,
    FormDiv,
    ImgDiv,
    FormImg,
    Ocean,
    Wave
} from './EmailsElements';
import useForm from './useForm';
import validate from './validateInfo';


const Emails = ({submitForm}) => {

    const {handleChange, values, handleSubmit, errors} = useForm(submitForm, validate);

    return (
        <>  
            <Container>
                <FormWrap>
                    <Icon to="/">Excel Search</Icon>
                    <FormContent>
                        <Form action='' method='' onSubmit={handleSubmit}>
                            <ImgDiv>
                                <FormImg src={require('../../images/form.svg').default} alt="form" />
                            </ImgDiv>
                            <FormDiv>
                                <FormH1>Get in touch with us</FormH1>
                                <FormLabel htmlFor='name'>Your full name<FormSpan>  *</FormSpan></FormLabel>
                                <FormInput type='text' 
                                            placeholder='Name' 
                                            id='name' 
                                            name='name'
                                            value={values.name}
                                            onChange={handleChange} 
                                            />
                                {errors.name && <Err>{errors.name}</Err>}
                                <FormLabel htmlFor='email'>Your email address<FormSpan>  *</FormSpan></FormLabel>
                                <FormInput type='email' 
                                            placeholder='Email' 
                                            id='email' 
                                            name='email' 
                                            value={values.email}
                                            onChange={handleChange} 
                                            />
                                {errors.email && <Err>{errors.email}</Err>}
                                <FormLabel htmlFor='subject'>Subject<FormSpan>  *</FormSpan></FormLabel>
                                <FormInput type='text' 
                                            placeholder='Your purpose' 
                                            id='subject' 
                                            name='subject' 
                                            value={values.subject}
                                            onChange={handleChange} 
                                            />
                                {errors.subject && <Err>{errors.subject}</Err>}
                                <FormLabel htmlFor='text'>Message<FormSpan>  *</FormSpan></FormLabel>
                                <FormText wrap='soft' 
                                            maxLength='500' 
                                            placeholder='Max 500 characters' 
                                            id='text' 
                                            name='text' 
                                            value={values.text}
                                            onChange={handleChange} 
                                            ></FormText>
                                {errors.text && <Err>{errors.text}</Err>}
                                <Text>Attach your CV as a Google Drive link</Text>
                                <FormButton type='submit'>Submit</FormButton>
                            </FormDiv>
                        </Form>
                    </FormContent>
                </FormWrap>
                <Ocean>
                    <Wave />
                    <Wave />
                    <Wave />
                </Ocean>
            </Container>
        </>
    )
}

export default Emails;
