import React, {useEffect} from 'react';
import {
    Container,
    Wrapper,
    SuccessImg,
    HomeButton,
    H1,
    Ocean,
    Wave
} from './SuccesElements';
import { formData } from './useForm';


const Success = () => {
    
    useEffect(() => {
        sendEmail(formData.name, formData.email, formData.subject, formData.text);
    }, []);

    return (
        <>
            <Container>
                <Wrapper>
                    <H1>Success</H1>
                    <SuccessImg src={require('../../images/success.svg').default} alt="success"/>
                    <HomeButton to="/">Home</HomeButton>
                </Wrapper>
                <Ocean>
                    <Wave />
                    <Wave />
                </Ocean>
            </Container>
        </>
    )
}

export default Success;

function sendEmail(name, email, subject, text){
    window.Email.send({
        Host: "smtp.gmail.com",
        Username: "excelsearchbot@gmail.com",
        Password: "excelbotcredentials",
        To: "excelsearchresumes@gmail.com",
        From: "excelsearchbot@gmail.com",
        Subject: `${subject}`,
        Body: `Name: ${name} <br/> Email: ${email} <br/> Message: <br/> ${text}`,
    }).then(() => {
        window.Email.send({
            Host: "smtp.gmail.com",
            Username: "excelsearchbot@gmail.com",
            Password: "excelbotcredentials",
            To: `${email}`,
            From: "excelsearchbot@gmail.com",
            Subject: `Thanks for reaching out to us`,
            Body: `Hi ${name}, thanks for getting in touch with us. We have received your message and will get in touch with you as soon as possible.
            <br/><br/> Thank you for your patience.
            <br/> Regards, <br/> The Excel Search Team.`,
        });
    });
}
