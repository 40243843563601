import { useState, useEffect } from "react";

export const formData = {
    name: '',
    email: '',
    subject: '',
    text: ''
};

const useForm = (callback, validate) => {
    const [values, setValues] = useState({
        name: '',
        email: '',
        subject: '',
        text: ''
    });

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = e => {
        const {name, value} = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    const handleSubmit = e => {
        e.preventDefault();

        setErrors(validate(values));
        setIsSubmitting(true);

        formData.name = values.name;
        formData.email = values.email;
        formData.subject = values.subject;
        formData.text = values.text;
    };

    useEffect(() => {
        if(Object.keys(errors).length === 0 && isSubmitting){
            callback()
        }
    }, [errors, callback, isSubmitting]);

    return {handleChange, values, handleSubmit, errors};
};

export default useForm;