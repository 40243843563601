import React, {useState} from 'react';
import Emails from '../components/EmailsPage/index';
import Success from '../components/EmailsPage/success';

const SendMessage = () => {
    
    const [isSubmitted, setIsSubmitted] = useState(false);

    function submitForm() {
        setIsSubmitted(true);
    }

    return (
        <div>
            {!isSubmitted ? <Emails submitForm={submitForm} /> : <Success />}
        </div>
    )
}

export default SendMessage;
