import React from 'react';
import {
    ReviewsContainer,
    ReviewsH1,
    ReviewsWrapper,
    ReviewsCard,
    ReviewsIcon,
    ReviewsH2,
    ReviewsP,
    ReviewSpan
} from './ReviewsElements';
import Icon1 from '../../images/m.svg';

const Reviews = () => {
    return (
        <ReviewsContainer id="reviews">
            <ReviewsH1>Candidate Reviews</ReviewsH1>
            <ReviewsWrapper>
                <ReviewsCard>
                    <ReviewsIcon src={Icon1}/>
                    <ReviewsH2>Suraj Nosib</ReviewsH2>
                    <ReviewsP>
                        "Excel Search got me the right job at the right time in my career progression. Very professional and client centric attitude to career growth." - <ReviewSpan>Head Compliance, Bank One Mauritius.</ReviewSpan>
                    </ReviewsP>
                </ReviewsCard>
                <ReviewsCard>
                    <ReviewsIcon src={Icon1}/>
                    <ReviewsH2>Avinash Shrivastava</ReviewsH2>
                    <ReviewsP>
                        "Excel Search is an excellent job consultant as it ensures definite positive closure taking care of each minute detail in all aspects. They justify their name in a true sense. They believe in long lasting relationships with the candidate. Highly recommend their services." - <ReviewSpan>Director, HDFC Bank Dubai.</ReviewSpan>
                    </ReviewsP>
                </ReviewsCard>
                <ReviewsCard>
                    <ReviewsIcon src={Icon1}/>
                    <ReviewsH2>Anil Kumar</ReviewsH2>
                    <ReviewsP>
                        "Excel Search is an excellent aid for placing Senior and middle management staff. Brilliant communication and strong talent are the hallmarks of the exceptional service they provide." - <ReviewSpan>Head Corporate, Banking Muscat Oman.</ReviewSpan>
                    </ReviewsP>
                </ReviewsCard>
            </ReviewsWrapper>
        </ReviewsContainer>
    )
}

export default Reviews;
