export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: false,
    topLine: 'Premium Consulting',
    headline: 'Reliable Partner',
    description: 'Excel Search is a pragmatic and professional recruitment firm and has established itself as a reputed international brand and a preferred recruitment partner for clients and trusted consultant for professionals. Our success lies in our extensive market coverage and vast database of highly skilled professionals.',
    buttonLabel: 'Learn More',
    imgStart: false,
    img: require('../../images/svg-1.svg').default,
    alt: 'consulting',
    dark: true,
    primary: true,
    darkText: false,
    buttonLink: "//Linkedin.com/in/excel-search-6b7249215",
    buttonTarget: "_blank"
};

export const homeObjTwo = {
    id: 'services',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'International Opportunities',
    headline: 'Global Market Coverage',
    description: 'We have established ourselves as a trusted international brand and the preferred recruitment partner for banks and IT firms as well as a trusted consultant for professionals. We currently operate out of India, Dubai, Oman, Qatar, Africa and Singapore, and are planning on expanding our horizons.',
    buttonLabel: 'Start Now',
    imgStart: true,
    img: require('../../images/svg-2.svg').default,
    alt: 'service',
    dark: false,
    primary: false,
    darkText: true,
    buttonLink: "//Linkedin.com/in/excel-search-6b7249215",
    buttonTarget: "_blank"
};

export const homeObjThree = {
    id: 'offices',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Online Offices',
    headline: 'Reach Us From Anywhere',
    description: 'Excel Search is a global firm with its headquarters in Mumbai, however we primarily operate on online platforms. We can be reached at any time on text, call or email and can be found on platforms like LinkedIn. Reach out to us at our company mail: excelsearch@esonline.in. The links to our socials can be found below.',
    buttonLabel: 'Get In Touch',
    imgStart: false,
    img: require('../../images/svg-3.svg').default,
    alt: 'office',
    dark: false,
    primary: false,
    darkText: true,
    buttonLink: "//esonline.in/form",
    buttonTarget: ""
};
